
.iconbutton{
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background-color: #7600bc;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 3%;
    bottom: 3%;
    cursor: pointer;
}



/* Recommendations */

.all_comments {
  /*  display: flex; */
      align-items: center;
  /*  margin-left: 1in; */
  /*  flex-direction: row; */
  /*  flex-flow: wrap; */
  /*  width: 100vw; */
    padding: 20px;
  /*  margin-left: 40px; */

    align-self: center;
  
  }

  
  
  .comment {
    font-style: italic;
    text-align: left;
    width: 41.875rem;
    padding: 1rem;
 /* background-color: #fff; */
    border-radius: 11px;
    box-shadow: 0 3px 10px var(--primary-shadow);
    padding: 20px;
    margin: 20px 40px 40px 40px;

    margin-bottom: 0px;
    
    border:1px solid gray;
    font-size: 20px;
    min-height: 150px
  }


  .reply{
    width: 38rem;
    font-size: 15px;
    

    border:1px solid gray;
    font-size: 20px;
    min-height: 150px;
    border-radius: 11px;
    box-shadow: 0 3px 10px var(--primary-shadow);
    padding: 20px;
    margin: 20px 0px 0px 100px;


    margin-bottom: 0px;
  }




/* Mobile view*/
  @media  (max-width: 600px) {
  .comment{
    width: 21rem;
    font-size: 15px;
    margin-left: -20px; 
  }

  .reply{
    width: 19rem;
    font-size: 15px;
    margin-left: 12px; 
  } 
  
  }

  

  /* Tablet view */
  @media (min-width: 601px) and (max-width: 1024px){
    .comment{
        width: 31rem;
        font-size: 15px;
        margin-left: 20px;
    }

    .reply{
      width: 26rem;
      font-size: 15px;
      margin-left: 100px;
    }
  }